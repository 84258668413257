@mixin bold {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem; // 16px
  line-height: 125%;
}
@mixin regular {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem; // 16px
  line-height: 125%;
}
@mixin small {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem; // 14px
  line-height: 125%;
}
@mixin small-bold {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem; // 14px
  line-height: 125%;
}
@mixin title1 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem; // 24px
  line-height: 125%;
}
@mixin title2 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem; // 24px
  line-height: 125%;
}
@mixin title3 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1.375rem; // 22px
  line-height: 125%;
}
@mixin title4 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem; // 18px
  line-height: 125%;
}
@mixin title6 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem; // 16px
  line-height: 150%;
}
.bold {
  @include bold;
}
.regular {
  @include regular;
}
.small {
  @include small;
}
.small-bold {
  @include small-bold;
}
.title1 {
  @include title1;
}
.title2 {
  @include title2;
}
.title3 {
  @include title3;
}
.title4 {
  @include title4;
}
.title6 {
  @include title6;
}
