:root {
  --border-radius: 6px;
  --header-height: 64px;
  --mobile-tab-height: 58px;
  --single-header-height: 66px;
  @include media(">desktop") {
    --header-height: 80px;
    --content-px: 142px;
  }
}
