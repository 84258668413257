// 2. Bootstrap default variable overrides
@import "bootstrap/scss/functions";

// scss-docs-start gray-color-variables
$gray-100: #f7f8f8;
$gray-200: #e6edee;
$gray-300: #ced4d5;
$gray-400: #b6bbbc;
$gray-600: #858989;
$gray-700: #6d7071;
$gray-800: #3c3e3e;
$gray-900: #29272b;
// scss-docs-end gray-color-variables

// scss-docs-start theme-color-variables
$primary: #175d68;
$secondary: #6d7071;
$success: #849c1c;
// scss-docs-end theme-color-variables

$enable-negative-margins: true;

// 4. Required Bootstrap stylesheets
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$input-border-color: $gray-300;
$input-placeholder-color: $gray-400;
$input-group-addon-border-color: $gray-300;
$table-border-color: $gray-300;
$table-group-separator-color: $gray-300;
$tooltip-bg: $gray-800;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-backdrop-opacity: 0.65;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$toast-background-color: rgba($white, 1);

// 5. Theme customization
// // scss-docs-start Custom map
$custom-colors: (
  "primary-dark": #044c57,
  "primary-light": #1f6d79,
  "primary-very-light": #277a87,
  "green-yellow": #afcb38,
  "cyan": #40d3dc,
);
// // scss-docs-end Custom map

// // scss-docs-start Merge the map
$theme-colors: map-merge($theme-colors, $custom-colors);
// // scss-docs-end Merge the map
$all-colors: map-merge-multiple($custom-colors, $grays);
$utilities: map-merge(
  $utilities,
  (
    "color":
      map-merge(
        map-get($utilities, "color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "color"), "values"),
              ($all-colors)
            ),
        )
      ),
    "background-color":
      map-merge(
        map-get($utilities, "background-color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "background-color"), "values"),
              ($all-colors)
            ),
        )
      ),
    "border-color":
      map-merge(
        map-get($utilities, "border-color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "border-color"), "values"),
              ($all-colors)
            ),
        )
      ),
  )
);

ul.parcel {
  li {
    margin-left: 1.5rem;
  }
}

// 6. Optional Bootstrap components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";
